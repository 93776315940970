import axios from 'axios';

export const fetchCohortAttributes = async () => {
    try {
        const url = constructCSMCohortUrl();
        const apiOutput = await axios.get(url, {
            withCredentials: true
        });
        const attributes = apiOutput?.data?.attributes || {};
        const filteredAttributes = Object.fromEntries(
            Object.entries(attributes).filter(([, value]) => value !== null)
        );
        return filteredAttributes || {};
    } catch {
        return {};
    }
};

const constructCSMCohortUrl = () =>
    window.SAC_BASE_URL +
    '/gateway/api/customer-context/customer/cohort/csm-experiment';
