import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Header, HeaderProps } from '../../components/header';
import Footer from '../../components/footer';
import RemoteReactApp from '../../components/remote-react-app';
import { CollectionTreeType } from '../../components/sidebar/sidebar.types';
import { MyRequests } from '../../../content';
import { useAuth } from '../../providers/auth-providers';
import { getFeatureGate } from '../../../statsig/statsig-client';
import Logger from '../../../logger';

interface MyRequestsEntry extends MyRequests {
    header: HeaderProps;
    preview: boolean;
}

interface WindowWithRequestURLs extends Window {
    CSP_REQUESTS_APP_MANIFEST_URL: string;
    CSP_REQUESTS_APP_MANIFEST_URL_BIFROST: string;
}

declare let window: WindowWithRequestURLs;

interface MyRequestsPageProps {
    entry: MyRequestsEntry;
    pageTree: CollectionTreeType[];
}

const getFilterKey: any = (fileKey: string) =>
    fileKey.endsWith('js') || fileKey.endsWith('css');

const getContentFiles: any = (content: any) => content.files;

const CspRequestsAppPage = ({
    entry: { resourceCards, header, id, preview }
}: MyRequestsPageProps) => {
    const [featureGateConfig, setFeatureGateConfig] = useState({
        isUpgradeAllowed: false,
        isFeatureFlagFetched: false
    });

    const { aaId } = useAuth();

    useEffect(() => {
        getFeatureGateFlag(aaId);
    }, [aaId]);

    const getFeatureGateFlag = async (accountId: string) => {
        let featureGateFlag = false;
        const statSigAccountId = { atlassianAccountId: accountId };
        try {
            featureGateFlag = await getFeatureGate(
                'csp_ui_deployment_upgrade_switch',
                false,
                statSigAccountId
            );
        } catch (error) {
            Logger.error({ error }, 'Error fetching feature flag');
        }
        setFeatureGateConfig({
            isUpgradeAllowed: featureGateFlag,
            isFeatureFlagFetched: true
        });
    };

    const getManifestUrl = () =>
        featureGateConfig.isUpgradeAllowed
            ? window.CSP_REQUESTS_APP_MANIFEST_URL +
              `?v=${new Date().getTime()}`
            : window.CSP_REQUESTS_APP_MANIFEST_URL_BIFROST +
              `?v=${new Date().getTime()}`;

    return (
        featureGateConfig.isFeatureFlagFetched && (
            <div className="flex-column-page">
                <div className="content-wrapper">
                    <Header
                        resources={resourceCards}
                        logo={header.atlassianSupportLogo.url}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        id={header.id}
                        showPreviewBanner={preview}
                        additionalLinks={header.additionalLinks}
                        pageId={id}
                    />
                    <div
                        className="main-content-container margin-top-large"
                        id="maincontent"
                    >
                        <RemoteReactApp
                            appTargetId="customer-support-portal"
                            manifestUrlProvider={getManifestUrl}
                            parseFilesFromManifestFile={getContentFiles}
                            filesFilter={getFilterKey}
                            includeLoadingSpinner={true}
                        />
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        )
    );
};

export default CspRequestsAppPage;
