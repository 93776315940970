import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import type { KbArticlePlatform } from '../../../content/types/content/kb-article';

const ServerSupportEndedMessage = () => (
    <>
        Support for Server* products ended on{' '}
        <span className="platform-notice__content--bold">
            February 15th 2024
        </span>
        . If you are running a Server product, you can visit the{' '}
        <a href="https://www.atlassian.com/migration/assess/journey-to-cloud">
            Atlassian Server end of support
        </a>{' '}
        announcement to review your migration options.
    </>
);

const ExceptFisheyeAndCrucibleMessage = () => (
    <span className="platform-notice__content--italic">
        *Except Fisheye and Crucible
    </span>
);

const CloudOnlyBody = () => (
    <div className="platform-notice">
        <p>
            <span className="platform-notice__content--bold">
                Platform Notice: Cloud Only
            </span>{' '}
            - This article only applies to Atlassian products on the{' '}
            <a href="https://confluence.atlassian.com/display/Cloud/Compare+Atlassian+cloud+vs+server">
                cloud platform
            </a>
            .
        </p>
    </div>
);

const CloudAndDataCenterBody = () => (
    <div className="platform-notice">
        <p>
            <span className="platform-notice__content--bold">
                Platform Notice: Cloud and Data Center
            </span>{' '}
            - This article applies equally to both{' '}
            <a
                href="https://www.atlassian.com/migration/assess/compare-cloud-data-center"
                className="platform-notice__content--bold"
            >
                cloud and data center platforms.
            </a>
        </p>
        <p>
            <ServerSupportEndedMessage />
        </p>
        <p>
            <ExceptFisheyeAndCrucibleMessage />
        </p>
    </div>
);

const DataCenterAndCloudByRequestBody = () => (
    <div className="platform-notice">
        <p>
            <span className="platform-notice__content--bold">
                Platform Notice: Data Center and Cloud By Request
            </span>{' '}
            - This article was written for the Atlassian{' '}
            <a href="https://www.atlassian.com/migration/assess/compare-cloud-data-center">
                Data Center platform
            </a>{' '}
            but may also be useful for Atlassian Cloud customers. If completing
            instructions in this article would help you, please{' '}
            <a href="https://support.atlassian.com/contact">
                contact Atlassian Support
            </a>{' '}
            and mention it.
        </p>
        <p>
            <ServerSupportEndedMessage />
        </p>
        <p>
            <ExceptFisheyeAndCrucibleMessage />
        </p>
    </div>
);

const DataCenterOnlyBody = () => (
    <div className="platform-notice">
        <p>
            <span className="platform-notice__content--bold">
                Platform Notice: Data Center Only
            </span>{' '}
            - This article only applies to Atlassian products on the{' '}
            <a href="https://confluence.atlassian.com/display/Cloud/Compare+Atlassian+cloud+vs+server">
                Data Center platform
            </a>
            .
        </p>
        <p>
            Note that this KB was created for the Data Center version of the
            product. Data Center KBs for non-Data-Center-specific features may
            also work for Server versions of the product, however they have not
            been tested. <ServerSupportEndedMessage />
        </p>
        <p>
            <ExceptFisheyeAndCrucibleMessage />
        </p>
    </div>
);

const PLATFORM_MAPPING: Record<KbArticlePlatform, React.ReactNode> = {
    'Cloud only': <CloudOnlyBody />,
    'Cloud and Data Center': <CloudAndDataCenterBody />,
    'Data Center only': <DataCenterOnlyBody />,
    'Data Center and Cloud by request': <DataCenterAndCloudByRequestBody />
};

const PlatformNotice = ({ platform }: { platform: KbArticlePlatform }) => {
    return (
        <div className="platform-notice__container">
            <SectionMessage>{PLATFORM_MAPPING[platform]}</SectionMessage>
        </div>
    );
};

export default PlatformNotice;
