import React, { Component } from 'react';
// @ts-ignore
import { Header, HeaderProps, Resources } from '../../components/header';
import Footer from '../../components/footer';
import { AtlasLink } from '../../components/atlas-link';
import { Search, handleOnSearch } from '../../components/search';
import './404.less';
import { translatableText } from '../../utils/translatable-text';

interface Props {
    entry: {
        resourceCards: Resources;
        title: string;
        message: string;
        header: HeaderProps;
        id: string;
        preview: boolean;
    };
    featureFlags: { isRedesignEnabled?: boolean };
}

export default class NotFound extends Component<Props> {
    render() {
        const { resourceCards, title, message, header, id, preview } =
            this.props.entry;

        const { isRedesignEnabled } = this.props.featureFlags;

        const placeholder = isRedesignEnabled
            ? translatableText.searchPlaceholderV2.text
            : translatableText.searchPlaceholder.text;

        return (
            <main
                className="not-found flex-column-page"
                data-testid="page-template"
            >
                <div className="content-wrapper">
                    <Header
                        resources={resourceCards}
                        logo={header.atlassianSupportLogo.url}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        id={header.id}
                        showPreviewBanner={preview}
                        additionalLinks={header.additionalLinks}
                        pageId={id}
                    />
                    <div
                        className="main-content-container margin-top-large"
                        id="maincontent"
                    >
                        <h1>{title}</h1>
                        <p>{message}</p>
                        <p>
                            {translatableText.visit.text + ' '}
                            <AtlasLink
                                href="/"
                                text="Atlassian Support"
                                dataName="atlassianSupportLink"
                            />
                            ,
                            {
                                translatableText.orTrySearchingOurDocumentation
                                    .text
                            }
                        </p>
                        <div className="not-found__search">
                            <Search
                                onSearch={handleOnSearch}
                                placeholder={placeholder}
                                analyticsMetadata={{
                                    parentComponent: '404'
                                }}
                                isRedesignEnabled={isRedesignEnabled}
                            />
                        </div>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </main>
        );
    }
}
