import React, { useMemo } from 'react';
import './title-section.less';
import Bug from '@atlaskit/icon-object/glyph/bug/16';
import Story16Icon from '@atlaskit/icon-object/glyph/story/16';
import { AtlassianIcon } from '@atlaskit/logo';

export enum TicketType {
    BUG = 'bug',
    SUGGESTION = 'suggestion'
}

interface TitleSectionProps {
    ticketType?: TicketType;
    title: string;
    iconUrl?: string;
}

export const TitleSection: React.FC<TitleSectionProps> = ({
    ticketType,
    title,
    iconUrl
}: TitleSectionProps) => {
    const StoryType = useMemo(() => {
        if (ticketType === TicketType.BUG) {
            return <Bug label="Bug" />;
        } else if (ticketType === TicketType.SUGGESTION) {
            return <Story16Icon label="Suggestion" />;
        }
    }, [ticketType]);

    const renderTicketIcon = () => (
        <div className="card-type" data-testid="ticket-type">
            {StoryType}
            <span className="label font-weight-400">
                {ticketType === TicketType.BUG ? 'Bug' : 'Suggestion'}
            </span>
        </div>
    );

    const renderProductIcon = () => {
        const icon = iconUrl ? (
            <div className="extra-padding" data-testid="icon-img-wrapper">
                <img src={iconUrl} height="24" alt="" />
            </div>
        ) : (
            <AtlassianIcon
                iconColor="#1968db"
                iconGradientStart="#1968db"
                iconGradientStop="#1968db"
            />
        );
        return (
            <div className="title-icon" data-testid="title-icon">
                {icon}
            </div>
        );
    };

    const titleIcon = ticketType ? renderTicketIcon() : renderProductIcon();

    return (
        <div className="title-section">
            {titleIcon}
            <h3
                className="card-title font-weight-600 text-gray"
                data-testid="title-section__title"
            >
                {title}
            </h3>
        </div>
    );
};
