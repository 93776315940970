import { MenuPrimaryListItemProps, MenuSecondaryListItemProps } from '..';

type ListItem = MenuPrimaryListItemProps | MenuSecondaryListItemProps;

interface SplitListItemsProps<T> {
    listItems: T[];
    splitBy: number;
    fillWithEmptyItems?: boolean;
}

// TODO: Move this function to transformer level
export const splitListItems = <T extends ListItem>({
    listItems,
    splitBy,
    fillWithEmptyItems = false
}: SplitListItemsProps<T>): T[][] => {
    const sections: any[] = [];
    const itemsPerSection = Math.floor(listItems.length / splitBy);
    let remainder = listItems.length % splitBy;

    let startIndex = 0;
    for (let i = 0; i < splitBy; i++) {
        const endIndex = startIndex + itemsPerSection + (remainder > 0 ? 1 : 0);
        sections.push(listItems.slice(startIndex, endIndex));
        startIndex = endIndex;
        if (remainder > 0) {
            remainder--;
        }
    }

    if (!fillWithEmptyItems) {
        return sections;
    }

    const maxRowSize = Math.max(...sections.map((section) => section.length));

    sections.forEach((section) => {
        while (section.length < maxRowSize) {
            section.push({});
        }
    });

    return sections;
};
