import React from 'react';
import './dropdown-content.less';
import { Menu, MenuPrimaryProps, MenuSecondaryProps } from '../menu';

interface DropdownContentProps {
    primarySection: MenuPrimaryProps;
    secondarySection?: MenuSecondaryProps;
    isOpen: boolean;
}

export const DropdownContent = ({
    primarySection,
    secondarySection,
    isOpen
}: DropdownContentProps) => {
    const classes = [];
    if (!isOpen) {
        classes.push('hidden');
    }

    return (
        <div data-testid="dropdown-content" className={classes.join(' ')}>
            <Menu
                primarySection={primarySection}
                secondarySection={secondarySection}
            />
        </div>
    );
};
