import { NoticeItem } from '../../../@types/sac';
import { GqlKbHomePageReference } from './kb-article';

export default interface Context {
    id: string;
    type: string;
    internalTitle: string;
    deployment: string;
    docsHomeSlug?: string;
    productNameReference: {
        id: string;
        productName: string;
        productNameId: string;
    };
    version: string;
    productBlurb: string;
    deploymentUrls: {
        [key: string]: string;
    };
    supportTitle: string | null;
    icon: {
        title: string;
        url: string;
    };
    iconAlternate?: {
        title: string;
        url: string;
    };
    customSupportFormAuthenticated: string;
    customSupportFormUnauthenticated: string;
    notice: NoticeItem | null;
    hasProductLandingPage: boolean;
}

export interface AppContext {
    id: string;
    type: string;
    appNameReference: { id: string; appName: string };
    docsHomeSlug?: string;
    deployment: string;
    icon: {
        title: string;
        url: string;
    };
    parentProductContext: {
        items: Context[];
    };
    deploymentUrls: {
        [key: string]: string;
    };
    notice: NoticeItem | null;
}

export interface ThemeContext {
    id: string;
    type: string;
    docsHomeSlug?: string;
    deployment: string;
    icon: {
        title: string;
        url: string;
    };
    hubName: string;
    notice: NoticeItem | null;
}

export type ContentfulContext = Context | AppContext | ThemeContext;

export type UrlParentEntry = ContentfulContext | GqlKbHomePageReference;

export function isProductContext(entry: UrlParentEntry): entry is Context {
    return !!(entry as Context).productNameReference;
}
export function isAppContext(entry: UrlParentEntry): entry is AppContext {
    return !!(entry as AppContext).appNameReference;
}
export function isThemeContext(entry: UrlParentEntry): entry is ThemeContext {
    return !!(entry as ThemeContext).hubName;
}
