import React, { Component } from 'react';
import { Header, HeaderType } from '../../components/header';
import Footer from '../../components/footer';
import { shape, arrayOf, string, bool } from 'prop-types';
import { PageTreeType } from '../../utils/page-tree-type';
import { ResourceType } from '../../components/card';
import './hot-contact.less';
import Breadcrumbs from '../../components/breadcrumbs';
import RemoteReactApp from '../../components/remote-react-app';

export default class HotContactPage extends Component {
    render() {
        const {
            entry: { resourceCards, header, id, preview, context },
            pageTree
        } = this.props;

        const manifestUrl = () =>
            `${window.CONTACT_FORMS_MANIFEST}?v=${new Date().getTime()}`;

        return (
            <div
                className="hot-contact flex-column-page"
                data-testid="page-template"
            >
                <div className="content-wrapper">
                    <Header
                        resources={resourceCards}
                        logo={header.atlassianSupportLogo}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        id={header.id}
                        showPreviewBanner={preview}
                        additionalLinks={header.additionalLinks}
                        pageId={id}
                    />
                    <main
                        className="main-content-container margin-top-large"
                        id="maincontent"
                    >
                        <div className="hot-contact__sub-header">
                            <Breadcrumbs
                                pageTree={pageTree}
                                entryId={id}
                                context={context}
                            />
                        </div>
                        <div className="hot-contact__forms">
                            <RemoteReactApp
                                appTargetId="support-form-app"
                                manifestUrlProvider={manifestUrl}
                                filesFilter={['hot-contact.js', 'index.css']}
                                includeLoadingSpinner={true}
                                executeAfterMount={() => {
                                    window.SUPPORT_FORM_APP = {
                                        IS_PARTNER_SUPPORT_TYPE: false
                                    };
                                }}
                            />
                        </div>
                    </main>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        );
    }
}

HotContactPage.propTypes = {
    entry: shape({
        resourceCards: shape({
            cards: arrayOf(ResourceType)
        }),
        header: HeaderType,
        id: string.isRequired,
        preview: bool
    }),
    pageTree: PageTreeType
};
