import React from 'react';
import { Header } from '../../components/header';
import Footer from '../../components/footer';
import {
    COLLECTION,
    TOPIC,
    TOPIC_SET,
    DOCUMENTED_PRODUCT
} from '../../../contentful/content-types';

export function PreviewErrorPage(entry) {
    const { header, id, contentType, resources } = entry;

    let message = "We can't build a preview until all required fields are set.";

    if (contentType === COLLECTION || contentType === DOCUMENTED_PRODUCT) {
        message = "We can't build a preview until the Context field is set.";
    } else if (contentType === TOPIC || contentType === TOPIC_SET) {
        message =
            "We can't build a preview until the Context and Collection Reference fields are set.";
    }

    return (
        <div
            className="preview-error flex-column-page"
            data-testid="page-template"
        >
            <div className="content-wrapper">
                <Header
                    logo={header.atlassianSupportLogo.url}
                    atlassianSupportLogo={header.atlassianSupportLogo}
                    cloudProducts={header.cloudProducts}
                    serverProducts={header.serverProducts}
                    id={header.id}
                    showPreviewBanner={true}
                    additionalLinks={header.additionalLinks}
                    pageId={id}
                    resources={resources}
                />
                <div
                    className="main-content-container margin-top-large"
                    id="maincontent"
                >
                    <p>{message}</p>
                </div>
            </div>
            <Footer
                logo={header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
}
