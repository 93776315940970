declare const process: {
    env: {
        CCG_DETAILS_URL: string;
        CMS_S3_IAM_ROLE: string;
        CONTENTFUL_DELIVERY_ACCESS_TOKEN: string;
        CONTENTFUL_ENV: string;
        CONTENTFUL_PREVIEW_ACCESS_TOKEN: string;
        CONTENTFUL_MANAGEMENT_ACCESS_TOKEN: string;
        CONTENTFUL_SPACE_ID: string;
        CONTENTFUL_WEBHOOK_PASSWORD: string;
        CONTENTFUL_WEBHOOK_USERNAME: string;
        CONTENT_GATEWAY_PORT: string;
        EXPRESS_SECRET: string;
        ID_AUTH_URL: string;
        LOG_LEVEL: string;
        MICROS_GROUP: string;
        MICROS_SERVICE: string;
        MICROS_ENV: string;
        MICROS_DEPLOYMENT_ID: string;
        MICROS_ENVTYPE: string;
        OPENID_PROVIDER_URL: string;
        OPENID_REALM: string;
        PORT: string;
        SAC_S3_BUCKET_NAME: string;
        SAC_S3_BUCKET_NAME_BACKUP: string;
        CONTENT_GATEWAY_URL: string;
        SAC_URL: string;
        SQS_SAC_PUBLISH_EVENTS_QUEUE_URL: string;
        SQS_SAC_PUBLISH_EVENTS_QUEUE_REGION: string;
        SQS_SCHEDULED_JOBS_QUEUE_URL: string;
        SQS_SCHEDULED_JOBS_QUEUE_REGION: string;
        CAC_API_PASSWORD: string;
        CAC_API_USERNAME: string;
        CAC_API_URL: string;
        ROOT_BUILD_PATH: string;
        SAC_ASSETS_PATH: string;
        CONTENTFUL_EXTENSIONS_PATH: string;
        CONTENTFUL_EXTENSIONS: string;
        SAC_RESOURCES_BASE_URL: string;
        EDITOR_ASSETS_PATH: string;
        EDITOR_RESOURCES_BASE_URL: string;
        CONTENTFUL_EXTENSION_PATH: string;
        URL_PREVIEW_EXTENSION_NAME: string;
        REDIRECT_DIALOG_EXTENSION_NAME: string;
        REFERENCES_MANAGER_EXTENSION_NAME: string;
        CST_APP_SHIM: string;
        CONTACT_FORMS_MANIFEST: string;
        CSM_CHAT_WIDGET_BASE_URL: string;
        BANNERMAN_MANIFEST: string;
        ALGOLIA_WRITE_KEY: string;
        ALGOLIA_APPLICATION_ID: string;
        ALGOLIA_INDEX_IPH_COPSI: string;
        ALGOLIA_INDEX_IPH_SIZE_LIMIT: string;
        ALGOLIA_WDC_INDEX_NAME: string;
        ALGOLIA_WDC_APPLICATION_ID: string;
        ALGOLIA_WDC_WRITE_KEY: string;
        ACE_DOMAIN_ID: string;
        LD_CLIENT_SDK_KEY: string;
        LD_SERVER_SDK_KEY: string;
        PARTNERS_CONFLUENCE_URL: string;
        MY_REQUESTS_APP_MANIFEST_URL: string;
        CSP_REQUESTS_APP_MANIFEST_URL: string;
        SENTRY_AUTH_TOKEN: string;
        SENTRY_EXTENSIONS_DSN: string;
        SENTRY_ORG: string;
        SENTRY_PROJECT_CONTENTFUL_EXTENSIONS: string;
        SENTRY_PUBLIC_SAC_DSN: string;
        CONTENTFUL_GRAPHQL_URL: string;
        SAC_CST_TOKEN_COOKIE_NAME: string;
        RELEASE_VERSION: string;
        SNS_NOTIFICATION_LIFECYCLE_QUEUE_REGION: string;
        SNS_NOTIFICATION_LIFECYCLE_QUEUE_URL: string;
        CST_STATIC_FORMS_URL: string;
        CST_STATIC_FORMS_MANIFEST_URL: string;
        SAC_CF_DIST_ID: string;
        MESH_DEPENDENCY_CONTENT_PLATFORM_API_BASE_URL: string;
        SAC_OIDC_TOKEN_COOKIE_NAME: string;
        CAC_URL: string;
        MESH_DEPENDENCY_GRAPHQL_GATEWAY_BASE_URL: string;
        ASAP_PRIVATE_KEY: string;
        ASAP_ISSUER: string;
        MICROS_PLATFORM_STATSD_HOST: string;
        MICROS_PLATFORM_STATSD_PORT: number;
        REDISX_CACHE_HOST: string;
        REDISX_CACHE_PORT: string;
        STATSIG_CLIENT_API_KEY: string;
        STATSIG_KEY: string;
        STREAMHUB_API_BASE_URL: string;
        SLACK_BOT_TOKEN: string;
        CSP_REQUESTS_APP_MANIFEST_URL_BIFROST: string;
    };
};

export const CCG_DETAILS_URL = process.env.CCG_DETAILS_URL;
export const CMS_S3_IAM_ROLE = process.env.CMS_S3_IAM_ROLE;
export const CONTENTFUL_DELIVERY_ACCESS_TOKEN =
    process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN;
export const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV;
export const CONTENTFUL_PREVIEW_ACCESS_TOKEN =
    process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
export const CONTENTFUL_MANAGEMENT_ACCESS_TOKEN =
    process.env.CONTENTFUL_MANAGEMENT_ACCESS_TOKEN;
export const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID;
export const CONTENTFUL_WEBHOOK_PASSWORD =
    process.env.CONTENTFUL_WEBHOOK_PASSWORD;
export const CONTENTFUL_WEBHOOK_USERNAME =
    process.env.CONTENTFUL_WEBHOOK_USERNAME;
export const CONTENT_GATEWAY_PORT = process.env.CONTENT_GATEWAY_PORT;
export const EXPRESS_SECRET = process.env.EXPRESS_SECRET;
export const LD_CLIENT_SDK_KEY = process.env.LD_CLIENT_SDK_KEY;
export const LD_SERVER_SDK_KEY = process.env.LD_SERVER_SDK_KEY;
export const ID_AUTH_URL = process.env.ID_AUTH_URL;
export const LOG_LEVEL = process.env.LOG_LEVEL;
export const MICROS_GROUP = process.env.MICROS_GROUP;
export const MICROS_SERVICE = process.env.MICROS_SERVICE;
export const MICROS_ENV = process.env.MICROS_ENV;
export const MICROS_DEPLOYMENT_ID = process.env.MICROS_DEPLOYMENT_ID;
export const MICROS_ENVTYPE = process.env.MICROS_ENVTYPE;
export const OPENID_PROVIDER_URL = process.env.OPENID_PROVIDER_URL;
export const OPENID_REALM = process.env.OPENID_REALM;
export const PORT = process.env.PORT;
export const SAC_S3_BUCKET_NAME = process.env.SAC_S3_BUCKET_NAME;
export const SAC_S3_BUCKET_NAME_BACKUP = process.env.SAC_S3_BUCKET_NAME_BACKUP;
export const CONTENT_GATEWAY_URL = process.env.CONTENT_GATEWAY_URL;
export const SAC_URL = process.env.SAC_URL;
export const SQS_SAC_PUBLISH_EVENTS_QUEUE_URL =
    process.env.SQS_SAC_PUBLISH_EVENTS_QUEUE_URL;
export const SQS_SAC_PUBLISH_EVENTS_QUEUE_REGION =
    process.env.SQS_SAC_PUBLISH_EVENTS_QUEUE_REGION;
export const CAC_API_PASSWORD = process.env.CAC_API_PASSWORD;
export const CAC_API_USERNAME = process.env.CAC_API_USERNAME;
export const CAC_API_URL = process.env.CAC_API_URL;
export const ROOT_BUILD_PATH = 'dist/';
export const SAC_ASSETS_PATH = 'dist/resources/'; // TODO: Here
export const CONTENTFUL_EXTENSIONS_PATH = 'dist/contentful-extensions/';
export const CONTENTFUL_EXTENSIONS = 'contentful-extensions';
export const SAC_RESOURCES_BASE_URL = 'resources';
export const EDITOR_ASSETS_PATH = 'dist/editor/';
export const EDITOR_RESOURCES_BASE_URL = 'editor';
export const CONTENTFUL_EXTENSION_PATH = 'contentful-extensions';
export const URL_PREVIEW_EXTENSION_NAME = 'url-preview';
export const REFERENCES_MANAGER_EXTENSION_NAME = 'references-manager';
export const REDIRECT_DIALOG_EXTENSION_NAME = 'redirect-dialog';
export const CST_APP_SHIM = process.env.CST_APP_SHIM;
export const CONTACT_FORMS_MANIFEST = process.env.CONTACT_FORMS_MANIFEST;
export const CSM_CHAT_WIDGET_BASE_URL = process.env.CSM_CHAT_WIDGET_BASE_URL;
export const BANNERMAN_MANIFEST = process.env.BANNERMAN_MANIFEST;
export const ALGOLIA_WRITE_KEY = process.env.ALGOLIA_WRITE_KEY;
export const ALGOLIA_APPLICATION_ID = process.env.ALGOLIA_APPLICATION_ID;
export const ALGOLIA_INDEX_IPH_SIZE_LIMIT =
    process.env.ALGOLIA_INDEX_IPH_SIZE_LIMIT;
export const ALGOLIA_WDC_INDEX_NAME = process.env.ALGOLIA_WDC_INDEX_NAME;
export const ALGOLIA_WDC_APPLICATION_ID =
    process.env.ALGOLIA_WDC_APPLICATION_ID;
export const ALGOLIA_WDC_WRITE_KEY = process.env.ALGOLIA_WDC_WRITE_KEY;
export const ACE_DOMAIN_ID = process.env.ACE_DOMAIN_ID;
export const PARTNERS_CONFLUENCE_URL = process.env.PARTNERS_CONFLUENCE_URL;
export const MY_REQUESTS_APP_MANIFEST_URL =
    process.env.MY_REQUESTS_APP_MANIFEST_URL;
export const CSP_REQUESTS_APP_MANIFEST_URL =
    process.env.CSP_REQUESTS_APP_MANIFEST_URL;
export const SENTRY_AUTH_TOKEN = process.env.SENTRY_AUTH_TOKEN;
export const SENTRY_EXTENSIONS_DSN = process.env.SENTRY_EXTENSIONS_DSN;
export const SENTRY_ORG = process.env.SENTRY_ORG;
export const SENTRY_PROJECT_CONTENTFUL_EXTENSIONS =
    process.env.SENTRY_PROJECT_CONTENTFUL_EXTENSIONS;
export const SENTRY_PUBLIC_SAC_DSN = process.env.SENTRY_PUBLIC_SAC_DSN;
export const CONTENTFUL_GRAPHQL_URL = process.env.CONTENTFUL_GRAPHQL_URL;
export const SAC_CST_TOKEN_COOKIE_NAME = process.env.SAC_CST_TOKEN_COOKIE_NAME;
export const RELEASE_VERSION = process.env.RELEASE_VERSION;
export const SQS_SCHEDULED_JOBS_QUEUE_URL =
    process.env.SQS_SCHEDULED_JOBS_QUEUE_URL;
export const SQS_SCHEDULED_JOBS_QUEUE_REGION =
    process.env.SQS_SCHEDULED_JOBS_QUEUE_REGION;
export const SNS_NOTIFICATION_LIFECYCLE_QUEUE_REGION =
    process.env.SNS_NOTIFICATION_LIFECYCLE_QUEUE_REGION;
export const SNS_NOTIFICATION_LIFECYCLE_QUEUE_URL =
    process.env.SNS_NOTIFICATION_LIFECYCLE_QUEUE_URL;
export const CST_STATIC_FORMS_URL = process.env.CST_STATIC_FORMS_URL;
export const CST_STATIC_FORMS_MANIFEST_URL =
    process.env.CST_STATIC_FORMS_MANIFEST_URL;
export const SAC_CF_DIST_ID = process.env.SAC_CF_DIST_ID;
export const MESH_DEPENDENCY_CONTENT_PLATFORM_API_BASE_URL =
    process.env.MESH_DEPENDENCY_CONTENT_PLATFORM_API_BASE_URL;
export const SAC_OIDC_TOKEN_COOKIE_NAME =
    process.env.SAC_OIDC_TOKEN_COOKIE_NAME;
export const CAC_URL = process.env.CAC_URL;
export const MESH_DEPENDENCY_GRAPHQL_GATEWAY_BASE_URL =
    process.env.MESH_DEPENDENCY_GRAPHQL_GATEWAY_BASE_URL;
export const ASAP_ISSUER = process.env.ASAP_ISSUER;
export const ASAP_PRIVATE_KEY = process.env.ASAP_PRIVATE_KEY;
export const MICROS_PLATFORM_STATSD_HOST =
    process.env.MICROS_PLATFORM_STATSD_HOST;
export const MICROS_PLATFORM_STATSD_PORT =
    process.env.MICROS_PLATFORM_STATSD_PORT;
export const REDISX_CACHE_HOST = process.env.REDISX_CACHE_HOST;
export const REDISX_CACHE_PORT = process.env.REDISX_CACHE_PORT;
export const STATSIG_CLIENT_API_KEY = process.env.STATSIG_CLIENT_API_KEY;
export const STATSIG_KEY = process.env.STATSIG_KEY;
export const STREAMHUB_URL = process.env.STREAMHUB_API_BASE_URL;
export const SLACK_BOT_TOKEN = process.env.SLACK_BOT_TOKEN;
export const CSP_REQUESTS_APP_MANIFEST_URL_BIFROST =
    process.env.CSP_REQUESTS_APP_MANIFEST_URL_BIFROST;

/** Functional form of {@link MICROS_GROUP} suitable for mocking. */
export function getMicrosGroup() {
    return MICROS_GROUP;
}
