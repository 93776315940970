import React from 'react';
import './menu.less';
import {
    MenuPrimary,
    MenuPrimaryProps,
    MenuSecondary,
    MenuSecondaryProps
} from '.';

export interface MenuProps {
    primarySection: MenuPrimaryProps;
    secondarySection?: MenuSecondaryProps;
}

export const Menu = ({ primarySection, secondarySection }: MenuProps) => {
    return (
        <div data-testid="menu" className="menu">
            <MenuPrimary
                listItems={primarySection.listItems}
                numberOfRows={primarySection.numberOfRows}
            />
            {secondarySection && (
                <MenuSecondary columns={secondarySection.columns} />
            )}
        </div>
    );
};
