import {
    AppContext,
    Context,
    getBreadcrumbTitle,
    isProductContext,
    ThemeContext
} from '../../../content/context-utils';
import { KbPageReferences } from '../../../content/types/content/kb-article';

interface Crumb {
    title: string;
    slug: string;
    id: string;
}

export const getContextBreadcrumbs = (
    context: Context | AppContext | ThemeContext,
    displayResourcesBreadcrumb: boolean
) => {
    const crumbs: Crumb[] = [];

    // Relevant product
    if (
        isProductContext(context) &&
        Object.keys(context).length > 0 &&
        context.hasProductLandingPage
    ) {
        crumbs.push({
            id: context.id,
            slug: context.deploymentUrls.default,
            title: context.productNameReference.productName
        });
    }

    // Display Resources breadcrumb if collection, topic set or topic
    if (displayResourcesBreadcrumb && context.docsHomeSlug) {
        crumbs.push({
            id: 'resources',
            slug: context.docsHomeSlug,
            title: getBreadcrumbTitle(context)
        });
    }

    return crumbs;
};

export const getKbPageBreadcrumbs = ({
    kbHomePageReference,
    kbArticlePageReference
}: KbPageReferences) => {
    return [
        {
            id: kbHomePageReference.url,
            title: kbHomePageReference.title,
            slug: kbHomePageReference.url
        },
        ...(kbArticlePageReference
            ? [
                  {
                      id: kbArticlePageReference.url,
                      title: kbArticlePageReference.title,
                      slug: kbArticlePageReference.url
                  }
              ]
            : [])
    ];
};

// This method returns JSON-LD structured data breadcrumb markup to assist with SEO
// https://developers.google.com/search/docs/data-types/breadcrumb
export const getStructuredData = (crumbs: Crumb[]) => {
    const listItems = crumbs.map((crumb, i) => ({
        '@type': 'ListItem',
        position: i + 1,
        item: {
            '@id': crumb.slug,
            name: crumb.title
        }
    }));

    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: listItems
    });
};
