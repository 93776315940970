import React from 'react';
import './menu-secondary.less';
import { AtlasLink } from '../../../atlas-link';
import { splitListItems } from '../../utils/split-list-items';

export interface MenuSecondaryListItemProps {
    title: string;
    url: string;
}

const MenuSecondaryListItem = ({ title, url }: MenuSecondaryListItemProps) => {
    return (
        <AtlasLink
            data-testid="menu-secondary-list-item"
            className="menu-secondary-list-item"
            href={url}
            aria-label={title}
            text={title}
        >
            {title}
        </AtlasLink>
    );
};

interface MenuSecondaryColumnProps {
    title: string;
    listItems: MenuSecondaryListItemProps[];
    numberOfColumns?: number;
}

const MenuSecondaryColumn = ({
    title,
    listItems,
    numberOfColumns
}: MenuSecondaryColumnProps) => {
    return (
        <div
            data-testid="menu-secondary-column"
            className="menu-secondary-column"
        >
            <span className="menu-secondary-column__title">{title}</span>
            <div
                data-testid="menu-secondary-column-items"
                className="menu-secondary-column__items"
            >
                {splitListItems({
                    listItems: listItems,
                    splitBy: numberOfColumns || 1
                }).map((columnItems, index) => (
                    <div
                        data-testid="menu-secondary-column-items-list"
                        className="menu-secondary-column__items__list"
                        key={index}
                    >
                        {columnItems.map((item) => (
                            <MenuSecondaryListItem
                                key={item.title}
                                title={item.title}
                                url={item.url}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export interface MenuSecondaryProps {
    columns: MenuSecondaryColumnProps[];
}

export const MenuSecondary = ({ columns }: MenuSecondaryProps) => {
    return (
        <div data-testid="menu-secondary" className="menu-secondary">
            {columns.map((column) => (
                <MenuSecondaryColumn
                    key={column.title}
                    title={column.title}
                    listItems={column.listItems}
                    numberOfColumns={column.numberOfColumns}
                />
            ))}
        </div>
    );
};
