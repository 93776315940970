import React, { Component } from 'react';
import { Header, HeaderType } from '../../components/header';
import Footer from '../../components/footer';
import { bool, shape, string } from 'prop-types';
import { AtlasLink } from '../../components/atlas-link';
import { PARTNERS_CONFLUENCE_URL } from '../../../common/config';
import RemoteReactApp from '../../components/remote-react-app';
import { translatableText } from '../../utils/translatable-text';

export default class PartnerContactPage extends Component {
    renderPartnerPortalButton() {
        return (
            <li className="header__nav-item partner-portal-btn">
                <AtlasLink
                    href={PARTNERS_CONFLUENCE_URL}
                    text={translatableText.partnerPortal.text}
                    dataName={'partner portal button'}
                />
            </li>
        );
    }

    render() {
        const {
            entry: { header, id, preview, headerLogo }
        } = this.props;

        const manifestUrl = () =>
            `${window.CONTACT_FORMS_MANIFEST}?v=${new Date().getTime()}`;

        return (
            <div className="contact flex-column-page">
                <div className="content-wrapper">
                    <Header
                        hideContactButton={true}
                        secondaryListItems={this.renderPartnerPortalButton()}
                        logo={headerLogo}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        products={null}
                        resources={null}
                        id={header.id}
                        showPreviewBanner={preview}
                        additionalLinks={header.additionalLinks}
                        showPartnerRequestItems={true}
                        pageId={id}
                    />
                    <div
                        className="main-content-container margin-top-large"
                        id="maincontent"
                    >
                        <div className="partner__contact__forms">
                            <RemoteReactApp
                                appTargetId="support-form-app"
                                manifestUrlProvider={manifestUrl}
                                filesFilter={['index.js', 'index.css']}
                                includeLoadingSpinner={true}
                                executeAfterMount={() => {
                                    window.SUPPORT_FORM_APP = {
                                        IS_PARTNER_SUPPORT_TYPE: true
                                    };
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        );
    }
}

PartnerContactPage.propTypes = {
    entry: shape({
        headerLogo: shape({
            url: string
        }),
        header: HeaderType,
        id: string.isRequired,
        preview: bool
    })
};
