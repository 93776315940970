import React from 'react';

export const highlightText = (text: string) => {
    const html = new DOMParser().parseFromString(text, 'text/html').body
        .childNodes;
    const sections: (string | JSX.Element | null)[] = [];

    html.forEach((node, i) => {
        if (node.nodeName === 'SPAN') {
            sections.push(
                <mark className="highlighted-text" key={i}>
                    <b>{node.textContent}</b>
                </mark>
            );
        } else {
            sections.push(node.textContent);
        }
    });

    return sections;
};
