import React from 'react';
import PropTypes from 'prop-types';

const SectionHeading = ({ title }) => {
    return <h2 className="menu-header">{title}</h2>;
};

SectionHeading.propTypes = {
    title: PropTypes.string.isRequired
};

export default SectionHeading;
