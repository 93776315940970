/* eslint-disable complexity */
import React, { createRef } from 'react';
import Footer from '../../components/footer';
// @ts-ignore
import { Header, HeaderProps, Resources } from '../../components/header';
import Sidebar from '../../components/sidebar';
import TableOfContents from '../../components/sidebar/table-of-contents';
import RightNav from '../../components/sidebar/right-nav';
import Community from '../../components/sidebar/community';
// @ts-ignore
import { CardGroup } from '../../components/card';
// @ts-ignore
import DeploymentSelector from '../../components/deployment-selector';
// @ts-ignore
import Breadcrumbs from '../../components/breadcrumbs';
import {
    PageTree,
    PageTreeList,
    PageTreeItem
} from '../../components/page-tree';
import './topic-set.less';
// @ts-ignore
import AuthorTools from '../../components/author-tools';
// @ts-ignore
import FeedbackButtons from '../../components/feedback-buttons';
// @ts-ignore
import AdditionalHelp from '../../components/additional-help';
import { CONTEXT_THEME, TOPIC_SET } from '../../../contentful/content-types';
import { Context, TopicSet } from '../../../content/types/content';
import { CollectionTreeType } from '../../components/sidebar/sidebar.types';
import { TopicReferenceTOCProp } from '../../../content/types/content/topic-reference-toc-prop';
import {
    isProductContext,
    getContextName,
    getSupportTitle
} from '../../../content/context-utils';
import { RelatedAppReference } from '../../../content/types/content/related-app-reference';
import NoticeHub from '../../components/notice-hub';
import Cards from '../../components/cards/cards';

export interface TopicSetEntry extends TopicSet {
    header: HeaderProps;
    preview: boolean;
    context: Context;
    collectionId: string;
    confluencePageId: string;
    relatedAppReferences: RelatedAppReference[];
    tableOfContents: TopicReferenceTOCProp[];
    resources: Resources;
}

interface SACTopicSetPageProps {
    entry: TopicSetEntry;
    pageTree: CollectionTreeType[];
    featureFlags: {
        isHardcodedAtlassianCloudHelpfulLinksEnabled: boolean;
        isHardcodedOpsgenieHelpfulLinksEnabled: boolean;
    };
}

const SACTopicSetPage = ({
    entry: {
        title,
        header,
        id,
        topicReferences,
        sectionOverview,
        preview,
        versions,
        context,
        collectionId,
        tableOfContents,
        url,
        resources,
        confluencePageId,
        collections,
        relatedAppReferences
    },
    pageTree
}: SACTopicSetPageProps) => {
    // stub these out
    const subContentRef = createRef<HTMLDivElement>();

    const collectionTree = pageTree.find((obj) => obj.id === collectionId);

    return (
        <>
            {
                <AuthorTools
                    pageId={id}
                    tooltipContent="Edit this topic set"
                    confluencePageId={confluencePageId}
                />
            }
            <div
                className="topic-set section-page flex-column-page"
                data-testid="page-template"
            >
                <div className="content-wrapper">
                    <Header
                        title={getSupportTitle(context)}
                        id={header.id}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        additionalLinks={header.additionalLinks}
                        showProductDropdownAsWaffle={true}
                        showPreviewBanner={preview}
                        pageId={id}
                        resources={resources}
                        supportHomePath={
                            isProductContext(context)
                                ? context.deploymentUrls[
                                      context.deployment.toLowerCase()
                                  ] || context.deploymentUrls.default
                                : '/'
                        }
                        shouldRenderDocumentation
                        collections={collections}
                        logo={header.logo}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                        relatedAppReferences={relatedAppReferences}
                        supportFormUrls={{
                            authenticated: isProductContext(context)
                                ? context.customSupportFormAuthenticated
                                : null,
                            unauthenticated: isProductContext(context)
                                ? context.customSupportFormUnauthenticated
                                : null
                        }}
                    />

                    <div className="main-content-container flex-container margin-top-medium">
                        <PageTree
                            context={context}
                            subContentRef={subContentRef}
                        >
                            <PageTreeList isRoot>
                                {pageTree.map(
                                    ({ title, slug, id, childList }) => (
                                        <PageTreeItem
                                            key={id}
                                            title={title}
                                            slug={slug}
                                            id={id}
                                            childList={childList}
                                        />
                                    )
                                )}
                            </PageTreeList>
                        </PageTree>
                        <main className="topic-set__container">
                            <div className="topic-set__sub-header">
                                <Breadcrumbs
                                    pageTree={pageTree}
                                    entryId={id}
                                    context={context}
                                    type={TOPIC_SET}
                                />
                                {context.type !== CONTEXT_THEME && (
                                    <DeploymentSelector
                                        selected={context.deployment}
                                        deployments={context.deploymentUrls}
                                        versions={versions}
                                    />
                                )}
                            </div>

                            {context.notice && (
                                <NoticeHub notice={context.notice} />
                            )}

                            <div
                                className="topic-set__sub-content margin-top-medium"
                                ref={subContentRef}
                                id="maincontent"
                            >
                                <div className="title margin-bottom-medium">
                                    <h1>{title}</h1>
                                </div>
                                <div
                                    className="topic-set__body"
                                    data-testid="topic_set_body"
                                >
                                    {sectionOverview && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: sectionOverview
                                            }}
                                        />
                                    )}
                                    <CardGroup>
                                        <Cards cards={topicReferences} />
                                    </CardGroup>
                                    <FeedbackButtons
                                        productName={
                                            isProductContext(context)
                                                ? context.internalTitle
                                                : getContextName(context)
                                        }
                                        pageId={id}
                                    />
                                    <AdditionalHelp
                                        productNames={[getContextName(context)]}
                                        deployments={[context.deployment]}
                                    />
                                </div>
                                <Sidebar>
                                    <RightNav
                                        currentPageId={id}
                                        collectionTree={collectionTree}
                                        topicReferences={topicReferences}
                                    />
                                    <TableOfContents
                                        links={tableOfContents}
                                        url={url}
                                        hideOnMobile={false}
                                    />
                                    <Community
                                        contextType={context.type}
                                        productName={getContextName(context)}
                                    />
                                </Sidebar>
                            </div>
                        </main>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        </>
    );
};
export default SACTopicSetPage;
