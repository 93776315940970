import React, { useState, useEffect } from 'react';
import { getExperiment } from '../../../statsig/statsig-client';
import {
    STATSIG_EXPERIMENTS,
    STATSIG_EXPERIMENTS_DEFAULTS
} from '../../../statsig/experiments';
import * as ct from 'countries-and-timezones';
import { useAuth } from '../../providers/auth-providers';
import analytics from '../../utils/analytics';
import CSMChatWidget from './csm-chat-widget';
import { fetchCohortAttributes } from './user-entitlement-service';

const CSMChatContainer = () => {
    const [shouldLoadCsmAgent, setShouldLoadCsmAgent] = useState(false);
    const { aaId, email } = useAuth();
    const emailDomain = getEmailDomain(email);

    useEffect(() => {
        if (aaId) {
            isCSMWidgetEnabled(aaId, emailDomain).then((isEnabled) => {
                setShouldLoadCsmAgent(!!isEnabled);
            });
        }
    }, [aaId, emailDomain]);

    return shouldLoadCsmAgent && <CSMChatWidget />;
};

const isCSMWidgetEnabled = async (aaId, emailDomain) => {
    // Do not evaluate the experiment if the user is on the contact or requests page
    const windowPathname = window?.location?.pathname;
    const windowHostname = window?.location?.hostname;
    if (shouldExcludeByPathnameOrHostname(windowPathname, windowHostname)) {
        return false;
    }

    const identifiers = {
        atlassianAccountId: aaId
    };

    const experimentAttributes = await createExperimentAttributes(
        aaId,
        emailDomain
    );

    const csmAgentExperiment = await getExperiment(
        STATSIG_EXPERIMENTS.CSM_AI_AGENT_ON_SAC,
        identifiers,
        experimentAttributes
    );
    sendExperimentEvent(csmAgentExperiment, aaId, experimentAttributes);
    setCSMWidgetConfig(aaId, experimentAttributes);

    return csmAgentExperiment.getValue(
        'agent_enabled',
        STATSIG_EXPERIMENTS_DEFAULTS[STATSIG_EXPERIMENTS.CSM_AI_AGENT_ON_SAC][
            'agent_enabled'
        ]
    );
};

const shouldExcludeByPathnameOrHostname = (windowPathname, windowHostname) => {
    const isContactPathname =
        windowPathname.startsWith('/contact') ||
        windowPathname.startsWith('/hot-contact') ||
        windowPathname.startsWith('/partnercontact') ||
        windowPathname.startsWith('/requests') ||
        windowPathname.startsWith('/named-contact');

    const isPartnerHostname = windowHostname.startsWith(
        'atlassianpartners.atlassian.net'
    );

    return isContactPathname || isPartnerHostname;
};

const sendExperimentEvent = async (
    experimentResult,
    aaId,
    customAttributes
) => {
    const eventMetadata = {
        action: 'evaluated',
        actionSubject: 'CSMWidgetExperiment',
        actionSubjectId: 'CSMWidgetExperimentEvaluated'
    };
    const attributes = {
        ...experimentResult,
        aaId,
        isAdmin: !!customAttributes?.isAdminEntitlementPresent
    };
    analytics.sendGASV3OperationalEvent(eventMetadata, attributes);
};

const setCSMWidgetConfig = (atlassianAccountId, experimentAttributes) => {
    window.atlassianWidgetConfig = {
        aaid: atlassianAccountId,
        config: {
            conversationContext: {
                ...experimentAttributes
            }
        }
    };
};

const createExperimentAttributes = async (aaId, emailDomain) => {
    const isAtlassianAccount = emailDomain === 'atlassian.com';
    const hashedEmailDomain = await calculateHash(emailDomain);

    const csmExperimentCohortAttributes = await fetchCohortAttributes();

    const attributes = {
        aaId,
        sacPath: window?.location?.pathname,
        hostname: window?.location?.hostname,
        isAtlassianAccount,
        hashedEmailDomain,
        countryCode: getLocationInfo().countryCode,
        ...csmExperimentCohortAttributes
    };
    return attributes;
};

const getEmailDomain = (email) => {
    return email?.split('@')?.at(1) || '';
};

const calculateHash = async (message) => {
    const msgBuffer = new TextEncoder().encode(message);
    // Hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // Convert ArrayBuffer to Array
    const hashArray = [...new Uint8Array(hashBuffer)];
    // Convert bytes to hex string
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
};

const getLocationInfo = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
    const countryCode = ct.getCountryForTimezone(timezone)?.id || '';

    return {
        countryCode: countryCode,
        timezone: timezone
    };
};

export default CSMChatContainer;
