import React from 'react';

import './footer-v2.less';
import { FooterV2Bottom } from './footer-v2-bottom';
import { AnalyticsMetadata } from '../../utils/analytics/types';
// @ts-ignore
import CSMChatContainer from '../csm-chat-widget/csm-chat-container';

interface FooterV2Props {
    analyticsMetadata: AnalyticsMetadata;
    isRedesignEnabled: boolean;
}

export const FooterV2 = ({
    analyticsMetadata,
    isRedesignEnabled
}: FooterV2Props) => {
    return (
        <footer className="footer-v2" data-testid="footer-v2">
            <CSMChatContainer />
            <div className="footer-v2__container">
                <FooterV2Bottom
                    analyticsMetadata={analyticsMetadata}
                    isRedesignEnabled={isRedesignEnabled}
                />
            </div>
        </footer>
    );
};
