import React from 'react';
import './menu-primary.less';
import { AtlasLink } from '../../../atlas-link';
import { splitListItems } from '../../utils/split-list-items';

export interface MenuPrimaryListItemProps {
    title: string;
    url: string;
    description: string;
}

const MenuPrimaryListItem = ({
    title,
    url,
    description
}: MenuPrimaryListItemProps) => {
    return title ? (
        <AtlasLink
            data-testid="menu-primary-list-item"
            className="menu-primary-list-item"
            href={url}
            aria-label={title}
            text={title}
        >
            <span className="menu-primary-list-item__title">{title}</span>
            {description && (
                <span className="menu-primary-list-item__description">
                    {description}
                </span>
            )}
        </AtlasLink>
    ) : (
        <div
            data-testid="menu-primary-list-item-invisible"
            className="menu-primary-list-item invisible-item"
            aria-hidden="true"
        ></div>
    );
};

export interface MenuPrimaryProps {
    listItems: MenuPrimaryListItemProps[];
    numberOfRows?: number;
}

export const MenuPrimary = ({ listItems, numberOfRows }: MenuPrimaryProps) => {
    return (
        <div data-testid="menu-primary" className="menu-primary">
            {splitListItems({
                listItems,
                splitBy: numberOfRows || 1,
                fillWithEmptyItems: true
            }).map((rowItems, index) => (
                <div
                    data-testid="menu-primary-row"
                    className="menu-primary__row"
                    key={index}
                >
                    {rowItems.map((item, index) => (
                        <MenuPrimaryListItem
                            key={item?.title || index}
                            title={item.title}
                            url={item.url}
                            description={item.description}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};
