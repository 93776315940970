import React, { Component } from 'react';
// @ts-ignore
import { Header, HeaderType } from '../../components/header';
import Footer from '../../components/footer';
import { ResourceType } from '../../components/card/card.type';
import { arrayOf, bool, shape, string } from 'prop-types';
import RemoteReactApp from '../../components/remote-react-app';

export default class FileDownloadPage extends Component {
    render() {
        const { resourceCards, header, id, preview } = this.props.entry;

        return (
            <main className="flex-column-page" data-testid="page-template">
                <div className="content-wrapper">
                    <Header
                        resources={resourceCards}
                        logo={header.atlassianSupportLogo.url}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        id={header.id}
                        showPreviewBanner={preview}
                        additionalLinks={header.additionalLinks}
                        pageId={id}
                    />
                    <div
                        className="main-content-container margin-top-large"
                        id="maincontent"
                    >
                        <RemoteReactApp
                            appTargetId="cst-static-forms-container"
                            manifestUrlProvider={() =>
                                window.CST_STATIC_FORMS_MANIFEST_URL
                            }
                            filesFilter={'js/cst-static-forms-api.js'}
                            onScriptLoad={() => {
                                if (window.CstStaticForm) {
                                    const staticForms =
                                        new window.CstStaticForm();
                                    staticForms.initialize({
                                        formName: 'file-download',
                                        height: '500',
                                        host: window.CST_STATIC_FORMS_URL
                                    });
                                    staticForms.display();
                                }
                            }}
                        />
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </main>
        );
    }
}

FileDownloadPage.propTypes = {
    entry: shape({
        resourceCards: shape({
            cards: arrayOf(ResourceType)
        }),
        header: HeaderType,
        id: string.isRequired,
        preview: bool
    })
};
