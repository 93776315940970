import {
    DOCUMENTED_PRODUCT,
    CONTEXT,
    PRODUCT_LANDING_PAGE,
    CONTEXT_APP,
    CONTEXT_THEME,
    HOME_PAGE,
    NOTICE_HUB,
    PAGE_KB_HOME,
    PAGE_KB_ARTICLE
} from '../contentful/content-types';
import {
    DocumentationPageOptions,
    getDocumentationPagePath
} from './util/get-documentation-page-path';
import {
    DocsHomeURLPathOptions,
    getDocsHomePath
} from './util/get-docs-home-path';
import { BasePathOptions, getBaseURLPath } from './util/get-base-url-path';
import {
    KbArticlePageOptions,
    getKbArticlePagePath
} from './util/get-kb-article-path';
import { KbHomePageOptions, getKbHomePagePath } from './util/get-kb-home-path';

type URLBuilderOptions =
    | BasePathOptions
    | DocsHomeURLPathOptions
    | DocumentationPageOptions
    | KbArticlePageOptions
    | KbHomePageOptions;

export { addDomainToPath } from './util/add-domain-to-path';

export { getURLPathByExtensionSDK } from './util/get-url-path-by-extension';

export { getURLPathDetails, URLPathDetails } from './util/get-url-path-details';

export { sneakifyCacUrl } from './util/sneakify-cac-url';

// eslint-disable-next-line complexity
export function getURLPath(contentType: string, options: URLBuilderOptions) {
    switch (contentType) {
        case HOME_PAGE:
            return '';
        case DOCUMENTED_PRODUCT:
            return getDocsHomePath(options);
        case CONTEXT_APP:
        case CONTEXT:
        case CONTEXT_THEME:
        case PRODUCT_LANDING_PAGE:
            return getBaseURLPath(options);
        case NOTICE_HUB:
            return '';
        case PAGE_KB_HOME:
            return getKbHomePagePath(options);
        case PAGE_KB_ARTICLE:
            return getKbArticlePagePath(options);
        default:
            return getDocumentationPagePath(options);
    }
}

export { prefixRelativeUrlPath } from './util/prefix-relative-url-path';
