import React, { ReactNode } from 'react';
import DropdownProducts from './products';
import DropdownResources from './resources';
import DropdownDocumentation from './documentation';
import DropdownSideNav, { AppAndThemeCards } from './side-nav';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
// @ts-ignore
import { ProductType } from '../product';
import DropdownGuides from './guides';
// @ts-ignore
import { GuideType } from '../../pages/collection/collection.type';
import './dropdown.less';
import { translatableText } from '../../utils/translatable-text';
import { GuidesMobileDropdownButton } from '../header/guides-dropdown-button';
import FocusLock from 'react-focus-lock';
import { CollectionReference } from '../../../content/types/content/collection';
import { SupportFormUrls } from '../contact';
import { AdditionalLinkType } from '../../pages/product-landing/product-landing-type';
import { Resources } from '../header';
import { Product } from '../product/product.type';
import { ProductsDropdown } from '../products-dropdown';
import { Menu } from '../../../content/types/content/menu';
import { DropdownContent } from './dropdown-content';

interface MobileDropdownProps {
    resources: Resources | undefined;
    products?: ProductType[];
    guides: GuideType[] | undefined;
    onChange: (type: string) => void;
    isOpen: boolean;
    collections: CollectionReference[] | undefined;
    dropdownClass?: string;
}

interface DropdownProps extends MobileDropdownProps {
    closeDropdown: () => void;
    type: string;
    id: string;
    cloudProducts: Product[];
    serverProducts: Product[];
    additionalLinks: AdditionalLinkType[];
    contentfulEditIcon?: ReactNode;
    className?: string;
    supportFormUrls?: SupportFormUrls;
    relatedAppReferences: AppAndThemeCards[] | undefined;
    showProductDropdownAsWaffle: boolean | undefined;
    menus?: Menu[];
    isRedesignEnabled?: boolean;
}

const MobileDropdownList = ({
    guides,
    resources,
    collections,
    products,
    onChange,
    isOpen,
    dropdownClass
}: MobileDropdownProps) => (
    <div
        data-testid="mobile_dropdown"
        className={[
            `${dropdownClass}__mobile-list`,
            isOpen ? '' : 'hide-dropdown'
        ].join(' ')}
    >
        {products && products.length && (
            <div
                data-testid="mobile_dropdown_item_products"
                className={`${dropdownClass}__mobile-item`}
                role="button"
                aria-label="Trigger products dropdown"
                tabIndex={0}
                onClick={() => {
                    onChange('Products');
                }}
                onKeyDown={(e) => {
                    e.key === 'Enter' && onChange('Products');
                }}
            >
                <span>{translatableText.products.text}</span>
                <ChevronRightIcon label="Products" />
            </div>
        )}
        {guides && (
            <GuidesMobileDropdownButton
                guides={guides}
                onClick={() => {
                    onChange('Get started');
                }}
            />
        )}
        {collections && collections?.length > 0 && (
            <div
                data-testid="mobile_dropdown_item_documentation"
                className={`${dropdownClass}__mobile-item`}
                role="button"
                aria-label="Trigger documentation dropdown"
                tabIndex={0}
                onClick={() => {
                    onChange('Documentation');
                }}
                onKeyDown={(e) => {
                    e.key === 'Enter' && onChange('Documentation');
                }}
            >
                <span>{translatableText.documentation.text}</span>
                <ChevronRightIcon label="Documentation" />
            </div>
        )}
        {resources && (
            <div
                data-testid="mobile_dropdown_item_resources"
                className={`${dropdownClass}__mobile-item`}
                role="button"
                aria-label="Trigger resources dropdown"
                tabIndex={0}
                onClick={() => {
                    onChange('Resources');
                }}
                onKeyDown={(e) => {
                    e.key === 'Enter' && onChange('Resources');
                }}
            >
                <span>{translatableText.resources.text}</span>
                <ChevronRightIcon label="Resources" />
            </div>
        )}
    </div>
);

// eslint-disable-next-line complexity
export const Dropdown = ({
    type,
    resources,
    guides,
    additionalLinks,
    contentfulEditIcon,
    className,
    isOpen,
    onChange,
    closeDropdown,
    supportFormUrls,
    collections,
    relatedAppReferences,
    cloudProducts,
    serverProducts,
    showProductDropdownAsWaffle,
    menus,
    isRedesignEnabled
}: DropdownProps) => {
    const dropdownClass = isRedesignEnabled ? 'dropdown-v2' : 'dropdown';
    const dropdownClassNames = [dropdownClass];

    let mobileProducts: ProductType[] = [];

    if (cloudProducts && serverProducts) {
        mobileProducts = [...new Set([...cloudProducts, ...serverProducts])];
    }

    if (!isOpen) {
        dropdownClassNames.push('hide-dropdown');
    }

    if (className) {
        dropdownClassNames.push(className);
    }

    return (
        <FocusLock returnFocus={true} noFocusGuards={true}>
            <div
                data-testid="dropdown_menu"
                className={dropdownClassNames.join(' ')}
            >
                <div className={`${dropdownClass}__inner-container`}>
                    <div
                        className={`${dropdownClass}__main-content-wrapper ${dropdownClass}__card-styling`}
                    >
                        {isRedesignEnabled ? (
                            <ProductsDropdown
                                className={`${dropdownClass}__main-content`}
                                cloudProducts={cloudProducts}
                                serverProducts={serverProducts}
                                isOpen={type === 'Products'}
                                analyticsMetadata={{
                                    parentComponent: 'products-dropdown'
                                }}
                                isRedesignEnabled
                            />
                        ) : (
                            <DropdownProducts
                                closeDropdown={closeDropdown}
                                className={`${dropdownClass}__main-content`}
                                isOpen={type === 'Products'}
                                cloudProducts={cloudProducts}
                                serverProducts={serverProducts}
                                showProductDropdownAsWaffle={
                                    showProductDropdownAsWaffle
                                }
                            />
                        )}

                        <DropdownGuides
                            closeDropdown={closeDropdown}
                            className={`${dropdownClass}__main-content`}
                            isOpen={type === 'Get started'}
                            guides={guides}
                        />

                        <DropdownDocumentation
                            closeDropdown={closeDropdown}
                            className={`${dropdownClass}__main-content`}
                            isOpen={type === 'Documentation'}
                            collections={collections}
                        />

                        {/* TODO: Create menus for each dropdown in Contentful */}
                        {isRedesignEnabled && menus ? (
                            menus.map((menu) => (
                                <DropdownContent
                                    key={menu.heading}
                                    isOpen={type === menu.heading}
                                    primarySection={{
                                        listItems: menu.primarySection.map(
                                            (card) => ({
                                                title: card.title,
                                                url: card.buttonLink,
                                                description:
                                                    card.shortDescription
                                            })
                                        ),
                                        numberOfRows: menu.primarySectionRows
                                    }}
                                    {...(menu?.secondarySection?.length && {
                                        secondarySection: {
                                            columns: menu.secondarySection.map(
                                                (section) => ({
                                                    title: section.heading,
                                                    listItems:
                                                        section.resources.map(
                                                            (resource) => ({
                                                                title: resource.linkText,
                                                                url: resource.url
                                                            })
                                                        ),
                                                    numberOfColumns:
                                                        section.numberOfColumns
                                                })
                                            )
                                        }
                                    })}
                                />
                            ))
                        ) : (
                            <DropdownResources
                                closeDropdown={closeDropdown}
                                className={`${dropdownClass}__main-content`}
                                isOpen={type === 'Resources'}
                                resources={resources}
                            />
                        )}

                        <MobileDropdownList
                            products={mobileProducts}
                            resources={resources}
                            collections={collections}
                            guides={guides}
                            onChange={onChange}
                            isOpen={
                                type !== 'Products' &&
                                type !== 'Resources' &&
                                type !== 'Get started' &&
                                type !== 'Documentation'
                            }
                            dropdownClass={dropdownClass}
                        />
                    </div>
                    {!isRedesignEnabled && (
                        <DropdownSideNav
                            className={`${dropdownClass}__side-content ${dropdownClass}__card-styling`}
                            additionalLinks={additionalLinks}
                            relatedAppReferences={relatedAppReferences}
                            supportFormUrls={supportFormUrls}
                            type={type}
                        />
                    )}
                    {contentfulEditIcon && contentfulEditIcon}
                </div>
            </div>
        </FocusLock>
    );
};
